<template>
  <v-card
    color="primary"
    dark
  >
    <v-card-title>
      <h5>Event {{ event._id }}</h5>

      <v-spacer />

      <wolk-event-dialog
        :id="event._id"
      />
    </v-card-title>

    <v-simple-table light>
      <template #default>
        <tbody>
          <tr>
            <td width="24em">
              Ontvangen
            </td>

            <td>
              {{ getHumanizedDate(event.createdAt) }}
            </td>
          </tr>

          <tr>
            <td>
              Type
            </td>

            <td>
              {{ event.type }}
            </td>
          </tr>

          <tr v-if="event.statusInfo">
            <td>
              Status
            </td>

            <td>
              <v-chip
                :color="getStatusInfo(event.statusInfo.parsedStatusKey).color"
                :text-color="
                  getStatusInfo(event.statusInfo.parsedStatusKey)
                    .inversTextColor ? 'white' : 'black'
                "
              >
                {{ getStatusInfo(event.statusInfo.parsedStatusKey).title }}
              </v-chip>
            </td>
          </tr>

          <tr v-if="event.statusInfo">
            <td>
              Firmware
            </td>

            <td>
              <firmware-version-chip :status-info="event.statusInfo || {}" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import {
  getHumanizedDate,
  getStatusInfo,
} from '../../../helpers/humanizer';

import FirmwareVersionChip from '../../FirmwareVersionChip.vue';
import WolkEventDialog from '../../WolkEventDialog.vue';

export default {
  name: 'WolkAdministrationEventCard',
  components: {
    FirmwareVersionChip,
    WolkEventDialog,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getHumanizedDate(millis) {
      return getHumanizedDate(millis);
    },
    getStatusInfo(statusKey) {
      return getStatusInfo(statusKey);
    },
  },
};
</script>
