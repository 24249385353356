<template>
  <v-row>
    <v-col cols="12">
      <v-card
        color="primary"
        dark
      >
        <v-card-title v-if="base === 'sleeve'">
          Koppelingen met boards
        </v-card-title>

        <v-card-title v-else-if="base === 'board'">
          Koppelingen met sleeves
        </v-card-title>
      </v-card>
    </v-col>

    <v-col
      v-for="ba in supplementedBoardAssignments"
      :key="ba._id"
      lg="6"
    >
      <v-card>
        <v-card-title v-if="base === 'sleeve'">
          Boardkoppeling
        </v-card-title>

        <v-card-title v-else-if="base === 'board'">
          Sleevekoppeling
        </v-card-title>

        <v-simple-table>
          <template #default>
            <tbody>
              <tr>
                <td class="wolk-simple-table-first-column">
                  Gekoppeld
                </td>

                <td>
                  {{ getHumanizedDate(ba.createdAt) }}
                </td>
              </tr>

              <tr>
                <td>
                  Onkoppeld
                </td>

                <td>
                  {{ ba.stoppedAt === null ? 'Nee' : getHumanizedDate(ba.stoppedAt) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="emitLoadEvents(ba._id)"
          >
            Laad events
          </v-btn>
        </v-card-actions>

        <template v-if="base === 'sleeve'">
          <v-card-title>
            Board
          </v-card-title>

          <v-simple-table>
            <template #default>
              <tbody>
                <tr>
                  <td>
                    iccid
                  </td>
                  <td>
                    {{ ba.board.iccid }}
                  </td>
                </tr>

                <tr>
                  <td>
                    ID
                  </td>
                  <td>
                    {{ ba.board.publicId }}
                  </td>
                </tr>

                <tr>
                  <td>
                    Type
                  </td>
                  <td>
                    {{ ba.board.type }}
                  </td>
                </tr>

                <tr>
                  <td>
                    Revisie
                  </td>
                  <td>
                    {{ ba.board.revision }}
                  </td>
                </tr>

                <tr>
                  <td class="wolk-simple-table-first-column">
                    Aangemaakt
                  </td>
                  <td>
                    {{ getHumanizedDate(ba.board.createdAt) }}
                  </td>
                </tr>

                <tr>
                  <td>
                    Commentaar
                  </td>
                  <td>
                    {{ ba.board.comment }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>

        <template v-if="base === 'board'">
          <v-card-title>
            Sleeve
          </v-card-title>

          <v-simple-table>
            <template #default>
              <tbody>
                <tr>
                  <td>
                    ID
                  </td>
                  <td>
                    {{ ba.sleeve.publicId }}
                  </td>
                </tr>

                <tr>
                  <td>
                    Type
                  </td>
                  <td>
                    {{ ba.sleeve.type }}
                  </td>
                </tr>

                <tr>
                  <td>
                    Maat
                  </td>
                  <td>
                    {{ ba.sleeve.size }}
                  </td>
                </tr>

                <tr>
                  <td>
                    Kleur
                  </td>
                  <td>
                    {{ ba.sleeve.color }}
                  </td>
                </tr>

                <tr>
                  <td>
                    Revisie
                  </td>
                  <td>
                    {{ ba.sleeve.revision }}
                  </td>
                </tr>

                <tr>
                  <td class="wolk-simple-table-first-column">
                    Aangemaakt
                  </td>
                  <td>
                    {{ getHumanizedDate(ba.sleeve.createdAt) }}
                  </td>
                </tr>

                <tr>
                  <td>
                    Commentaar
                  </td>
                  <td>
                    {{ ba.sleeve.comment }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </v-card>
    </v-col>

    <v-col v-if="supplementedBoardAssignments.length === 0">
      <p>Geen koppelingen gevonden</p>
    </v-col>
  </v-row>
</template>

<script>
import { getHumanizedDate } from '../../helpers/humanizer';

export default {
  name: 'WolkAdministrationBoardAssignmentsCard',
  props: {
    base: {
      type: String,
      required: true,
      validator: (value) => ['board', 'sleeve'].indexOf(value) !== -1,
    },
    boardAssignments: {
      type: Array,
      required: true,
    },
    boards: {
      type: Array,
      required: true,
    },
    sleeves: {
      type: Array,
      required: true,
    },
  },
  computed: {
    supplementedBoardAssignments() {
      if (this.base === 'board') {
        return this.boardAssignments.reduce((p, c) => {
          const foundSleeve = this.sleeves.find((s) => s._id === c.references.sleeveId);

          if (!foundSleeve) {
            return p;
          }

          return [
            ...p,
            {
              ...c,
              sleeve: foundSleeve,
            }];
        }, []);
      }

      if (this.base === 'sleeve') {
        return this.boardAssignments.reduce((p, c) => {
          const foundBoard = this.boards.find((b) => b._id === c.references.boardId);

          if (!foundBoard) {
            return p;
          }

          return [
            ...p,
            {
              ...c,
              board: foundBoard,
            }];
        }, []);
      }

      throw Error(`Can not handle base ${this.base}`);
    },
  },
  methods: {
    getHumanizedDate(millis) {
      return getHumanizedDate(millis);
    },
    emitLoadEvents(id) {
      const boardAssignment = this.boardAssignments.find((ba) => ba._id === id);
      if (!boardAssignment) {
        return;
      }

      this.$emit(
        'load-events',
        {
          key: 'boardAssignment',
          id: boardAssignment._id,
          eventsBlockTitle: `Events van boardkoppeling ${boardAssignment._id}`,
        },
      );
    },
  },
};
</script>

<style scoped>
  .wolk-simple-table-first-column {
    width: 10em;
  }
</style>
