<template>
  <v-card>
    <v-card-title>
      Gevonden Board
    </v-card-title>

    <v-simple-table>
      <template #default>
        <tbody>
          <tr>
            <td class="wolk-simple-table-first-column">
              iccid
            </td>
            <td>
              {{ board.iccid }}
            </td>
          </tr>

          <tr>
            <td>
              ID
            </td>
            <td>
              {{ board.publicId }}
            </td>
          </tr>

          <tr>
            <td>
              Type
            </td>
            <td>
              {{ board.type }}
            </td>
          </tr>

          <tr>
            <td>
              Revisie
            </td>
            <td>
              {{ board.revision }}
            </td>
          </tr>

          <tr>
            <td>
              Aangemaakt
            </td>
            <td>
              {{ getHumanizedDate(board.createdAt) }}
            </td>
          </tr>

          <tr>
            <td>
              Commentaar
            </td>
            <td>
              {{ board.comment }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-actions>
      <v-btn
        color="primary"
        text
        @click="$emit(
          'load-events',
          {
            key: 'board',
            id: board._id,
            eventsBlockTitle: `Events van Board ${board.iccid}`,
          },
        )"
      >
        Laad events
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getHumanizedDate } from '@/helpers/humanizer';

export default {
  name: 'WolkAdministrationBoardCard',
  props: {
    board: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getHumanizedDate(millis) {
      return getHumanizedDate(millis);
    },
  },
};
</script>

<style scoped>
  .wolk-simple-table-first-column {
    width: 10em;
  }
</style>
