var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Gevonden Sleeve ")]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"wolk-simple-table-first-column"},[_vm._v(" ID ")]),_c('td',[_vm._v(" "+_vm._s(_vm.sleeve.publicId)+" ")])]),_c('tr',[_c('td',[_vm._v(" Type ")]),_c('td',[_vm._v(" "+_vm._s(_vm.sleeve.type)+" ")])]),_c('tr',[_c('td',[_vm._v(" Maat ")]),_c('td',[_vm._v(" "+_vm._s(_vm.sleeve.size)+" ")])]),_c('tr',[_c('td',[_vm._v(" Kleur ")]),_c('td',[_vm._v(" "+_vm._s(_vm.sleeve.color)+" ")])]),_c('tr',[_c('td',[_vm._v(" Revisie ")]),_c('td',[_vm._v(" "+_vm._s(_vm.sleeve.revision)+" ")])]),_c('tr',[_c('td',[_vm._v(" Aangemaakt ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getHumanizedDate(_vm.sleeve.createdAt))+" ")])]),_c('tr',[_c('td',[_vm._v(" Commentaar ")]),_c('td',[_vm._v(" "+_vm._s(_vm.sleeve.comment)+" ")])])])]},proxy:true}])}),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit(
        'load-events',
        {
          key: 'sleeve',
          id: _vm.sleeve._id,
          eventsBlockTitle: ("Events van Sleeve " + (_vm.sleeve.publicId)),
        }
      )}}},[_vm._v(" Laad events ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }