<template>
  <div>
    <v-tooltip
      v-if="displayData.hasWarning"
      top
    >
      <template #activator="{ on, attrs }">
        <v-chip
          :color="displayData.color"
          v-bind="attrs"
          v-on="on"
          @click="dialogOpen = true;"
        >
          {{ displayData.text }}
        </v-chip>
      </template>

      <span>Zie update instructies</span>
    </v-tooltip>

    <v-chip
      v-else
      :color="displayData.color"
    >
      {{ displayData.text }}
    </v-chip>

    <v-dialog
      v-model="dialogOpen"
      width="400"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Update uitvoeren
        </v-card-title>

        <v-card-text class="mt-4">
          <p>
            Deze Wolk heeft niet de laatste software en moet ge-update worden.
          </p>

          <h3>Hoe?</h3>

          <p>
            Leg Wolk een uur gesloten aan de lader, en dan update de Wolk automatisch!
          </p>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="primary"
            text
            @click="dialogOpen = false"
          >
            Sluit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getHumanizedFirmwareVersionInfo } from '../helpers/humanizer';

export default {
  name: 'FirmwareVersionChip',
  props: {
    statusInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
  computed: {
    displayData() {
      return getHumanizedFirmwareVersionInfo(this.statusInfo);
    },
  },
};
</script>
