<template>
  <div>
    <v-btn
      :loading="loading"
      color="white"
      icon
      @click="show"
    >
      <v-icon>
        mdi-magnify
      </v-icon>
    </v-btn>

    <v-dialog
      v-model="dialogOpen"
      width="600"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Raw event
        </v-card-title>

        <v-card-text class="mt-4">
          <pre>{{ event }}</pre>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="primary"
            text
            @click="dialogOpen = false"
          >
            Sluit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WolkEventDialog',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialogOpen: false,
      loading: false,
      event: null,
    };
  },
  methods: {
    show() {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/events/${this.id}`;

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      })
        .then((response) => {
          this.event = response.data.event;

          this.dialogOpen = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
