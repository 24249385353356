<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Zoeken

            <v-spacer />
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col md="6">
                <v-text-field
                  v-model="searchQuery"
                  append-icon="mdi-magnify"
                  label="Zoeken"
                  single-line
                  hint="Zoek op riem nummer, board nummer of SIM nummer."
                  persistent-hint
                  @keyup.enter="submitInitialQuery"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <template v-if="true">
              <v-btn
                :disabled="!currentQueryIsValid"
                color="primary"
                text
                @click="submitInitialQuery"
              >
                Zoek
              </v-btn>

              <v-btn
                :disabled="!canResetQuery"
                text
                @click="reset"
              >
                <span>reset</span>
              </v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col v-if="shouldShowSearchResultsCard">
        <v-card>
          <v-card-title>
            Resultaten

            <v-spacer />
          </v-card-title>

          <v-card-text v-if="initialResponseLoading">
            <v-progress-linear
              indeterminate
              color="primary"
            />
          </v-card-text>

          <v-card-text v-else-if="shouldShowBoardResponse">
            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <wolk-administration-board-card
                  :board="initialResponse.board"
                  @load-events="loadEvents"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <wolk-administration-board-assignments-card
                  base="board"
                  :board-assignments="initialResponse.boardAssignments"
                  :boards="[]"
                  :sleeves="initialResponse.sleeves"
                  @load-events="loadEvents"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text v-if="shouldShowSleeveResponse">
            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <wolk-administration-sleeve-card
                  :sleeve="initialResponse.sleeve"
                  @load-events="loadEvents"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <wolk-administration-board-assignments-card
                  base="sleeve"
                  :board-assignments="initialResponse.boardAssignments"
                  :boards="initialResponse.boards"
                  :sleeves="[]"
                  @load-events="loadEvents"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <wolk-administration-sleeve-assignments-card
                  :sleeve-assignments="initialResponse.sleeveAssignments"
                  :organizations="initialResponse.organizations"
                  @load-events="loadEvents"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        v-if="eventsBlock.scope && eventsBlock.id"
        ref="eventsContainer"
        cols="12"
      >
        <v-card v-if="eventsBlock.items === null">
          <v-card-text>
            <v-progress-linear
              indeterminate
              color="primary"
            />
          </v-card-text>
        </v-card>

        <wolk-administration-events-list-card
          v-else
          :events="eventsBlock.items"
          :title="eventsBlock.title"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

import { createBreadcrumbs } from '../../helpers/humanizer';

import WolkAdministrationBoardAssignmentsCard
from '../../components/administration/BoardAssignmentsCard.vue';
import WolkAdministrationBoardCard from '../../components/administration/BoardCard.vue';
import WolkAdministrationSleeveAssignmentsCard
from '../../components/administration/SleeveAssignmentsCard.vue';
import WolkAdministrationSleeveCard from '../../components/administration/SleeveCard.vue';

import WolkAdministrationEventsListCard from '../../components/administration/events/List.vue';

const validNumberStrings = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const toNumberString = (input) => {
  if (typeof input !== 'string') {
    return '';
  }

  return input.split('').reduce((p, c) => {
    if (validNumberStrings.includes(c)) {
      return `${p}${c}`;
    }

    return p;
  }, '');
};
const isValidQuery = (input) => {
  if (typeof input !== 'string') {
    return false;
  }

  // validation of board's publicID
  const boardIdPattern = /^(WLK00[0-9](S|M|L|XL)[0-9]+)$|^WLK[0-9]+(S|M|L|XL)$/;
  if (boardIdPattern.test(input)) {
    return true;
  }

  if (toNumberString(input) !== input) {
    // Not only a number string
    return false;
  }

  if (input.length === 6) {
    // Found a sleeve ID
    return true;
  }

  if (input.length === 19) {
    // Found a iccid
    return true;
  }

  return false;
};

export default {
  name: 'WolkAdministrationSearchPage',
  components: {
    WolkAdministrationBoardAssignmentsCard,
    WolkAdministrationBoardCard,
    WolkAdministrationEventsListCard,
    WolkAdministrationSleeveAssignmentsCard,
    WolkAdministrationSleeveCard,
  },
  data() {
    return {
      searchQuery: '',
      initialResponseLoading: false,
      initialResponse: null,
      eventsBlock: {
        loading: false,
        scope: null,
        id: null,
        title: null,
        items: null,
      },
    };
  },
  computed: {
    canResetQuery() {
      if (this.initialResponseLoading) {
        return false;
      }

      return this.searchQuery !== '';
    },
    currentQueryIsValid() {
      return isValidQuery(this.searchQuery);
    },
    breadcrumbs() {
      return createBreadcrumbs(
        this.$store.state.app.dashboardHomeName,
        [
          {
            text: 'Administratie',
            disabled: true,
            exact: true,
            to: { name: 'home' },
          },
          {
            text: 'Zoeken',
            disabled: true,
            exact: true,
            to: { name: 'administrationSearch' },
          },
        ],
        false,
      );
    },
    shouldShowSearchResultsCard() {
      if (this.initialResponseLoading) {
        return true;
      }

      if (this.initialResponse !== null) {
        return true;
      }

      return false;
    },
    shouldShowBoardResponse() {
      if (!this.initialResponse) {
        return false;
      }

      return this.initialResponse.queryResultedTo === 'board';
    },
    shouldShowSleeveResponse() {
      if (!this.initialResponse) {
        return false;
      }

      return this.initialResponse.queryResultedTo === 'sleeve';
    },
  },
  beforeMount() {
    if (isValidQuery(this.$route.query.q)) {
      this.searchQuery = toNumberString(this.$route.query.q);
    }

    if (this.currentQueryIsValid) {
      this.loadInitialResponse(this.searchQuery);
    }
  },
  methods: {
    reset() {
      if (this.initialResponseLoading) {
        return;
      }

      this.searchQuery = '';
      this.initialResponse = null;
      this.resetEventsBlock();

      this.$router.push({ name: this.$route.name });
    },
    resetEventsBlock() {
      this.eventsBlock.loading = false;
      this.eventsBlock.scope = null;
      this.eventsBlock.id = null;
      this.eventsBlock.title = null;
      this.eventsBlock.items = null;
    },
    submitInitialQuery() {
      if (!this.currentQueryIsValid) {
        return;
      }

      if (this.searchQuery.length === 5) {
        this.searchQuery = `0${this.searchQuery}`;
      }

      if (this.searchQuery !== this.$route.query.q) {
        this.$router.push({ name: this.$route.name, query: { q: this.searchQuery } });
      }

      this.initialResponse = null;
      this.resetEventsBlock();

      this.loadInitialResponse(this.searchQuery);
    },
    loadInitialResponse(query) {
      const url = `${config.VUE_APP_API_BASE_URL}/administration/search`;
      this.initialResponseLoading = true;
      this.initialResponse = null;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data: {
          query,
        },
        method: 'post',
      })
        .then((response) => {
          this.initialResponse = response.data;
        })
        .finally(() => {
          this.initialResponseLoading = false;
        });
    },
    loadEvents(input) {
      const params = {
        limit: 20,
        allowWithoutOrganization: true,
      };

      if (input.key === 'board') {
        params.boardId = input.id;
      } else if (input.key === 'boardAssignment') {
        params.boardAssignmentId = input.id;
      } else if (input.key === 'sleeve') {
        params.sleeveId = input.id;
      } else if (input.key === 'sleeveAssignment') {
        params.sleeveAssignmentId = input.id;
      }

      const url = `${config.VUE_APP_API_BASE_URL}/events`;

      this.eventsBlock.items = null;
      this.eventsBlock.scope = input.key;
      this.eventsBlock.id = input.id;
      this.eventsBlock.title = input.eventsBlockTitle || `Events van ${input.key} ${input.id}`;
      this.eventsBlock.loading = true;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        params,
        method: 'get',
      })
        .then((response) => {
          this.eventsBlock.items = response.data.events;
        })
        .finally(() => {
          this.eventsBlock.loading = false;

          this.$nextTick(this.scrollEventsIntoView);
        });
    },
    scrollEventsIntoView() {
      this.$refs.eventsContainer.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    },
  },
};
</script>
