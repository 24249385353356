<template>
  <v-row>
    <v-col cols="12">
      <v-card
        color="primary"
        dark
      >
        <v-card-title>
          Koppelingen met organisaties
        </v-card-title>
      </v-card>
    </v-col>

    <v-col
      v-for="sa in supplementedSleeveAssignments"
      :key="sa._id"
      lg="6"
    >
      <v-card>
        <v-card-title>
          Organisatiekoppeling {{ sa.organization.title }}
        </v-card-title>

        <v-simple-table>
          <template #default>
            <tbody>
              <tr>
                <td class="wolk-simple-table-first-column">
                  Gekoppeld
                </td>

                <td>
                  {{ getHumanizedDate(sa.createdAt) }}
                </td>
              </tr>

              <tr>
                <td>
                  Onkoppeld
                </td>

                <td>
                  {{ sa.stoppedAt === null ? 'Nee' : getHumanizedDate(sa.stoppedAt) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="emitLoadEvents(sa._id)"
          >
            Laad events
          </v-btn>
        </v-card-actions>

        <v-card-title>
          Organisatie
        </v-card-title>

        <v-simple-table>
          <template #default>
            <tbody>
              <tr>
                <td class="wolk-simple-table-first-column">
                  Naam
                </td>

                <td>
                  {{ sa.organization.title }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>

    <v-col v-if="supplementedSleeveAssignments.length === 0">
      <p>Geen koppelingen gevonden</p>
    </v-col>
  </v-row>
</template>

<script>
import { getHumanizedDate } from '../../helpers/humanizer';

export default {
  name: 'WolkAdministrationSleeveAssignmentsCard',
  props: {
    sleeveAssignments: {
      type: Array,
      required: true,
    },
    organizations: {
      type: Array,
      required: true,
    },
  },
  computed: {
    supplementedSleeveAssignments() {
      return this.sleeveAssignments.reduce((p, c) => {
        const foundOrganization = this
          .organizations
          .find((o) => o._id === c.references.organizationId);

        if (!foundOrganization) {
          return p;
        }

        return [...p, { ...c, organization: foundOrganization }];
      }, []);
    },
  },
  methods: {
    getHumanizedDate(millis) {
      return getHumanizedDate(millis);
    },
    emitLoadEvents(id) {
      const sleeveAssignment = this.sleeveAssignments.find((ba) => ba._id === id);
      if (!sleeveAssignment) {
        return;
      }

      this.$emit(
        'load-events',
        {
          key: 'sleeveAssignment',
          id: sleeveAssignment._id,
          eventsBlockTitle: `Events van organisatiekoppeling ${sleeveAssignment._id}`,
        },
      );
    },
  },
};
</script>

<style scoped>
  .wolk-simple-table-first-column {
    width: 10em;
  }
</style>
