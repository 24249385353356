<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ title }}
        </v-card-title>

        <v-card-text v-if="events.length === 0">
          <p>Geen events gevonden</p>
        </v-card-text>

        <v-card-text v-else>
          <v-row>
            <v-col
              v-for="event in events"
              :key="event._id"
              cols="12"
            >
              <wolk-administration-event-card
                :event="event"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import WolkAdministrationEventCard from './Card.vue';

export default {
  name: 'WolkAdministrationEventsListCard',
  components: {
    WolkAdministrationEventCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
  },
};
</script>
