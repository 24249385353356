<template>
  <v-card>
    <v-card-title>
      Gevonden Sleeve
    </v-card-title>

    <v-simple-table>
      <template #default>
        <tbody>
          <tr>
            <td class="wolk-simple-table-first-column">
              ID
            </td>
            <td>
              {{ sleeve.publicId }}
            </td>
          </tr>

          <tr>
            <td>
              Type
            </td>
            <td>
              {{ sleeve.type }}
            </td>
          </tr>

          <tr>
            <td>
              Maat
            </td>
            <td>
              {{ sleeve.size }}
            </td>
          </tr>

          <tr>
            <td>
              Kleur
            </td>
            <td>
              {{ sleeve.color }}
            </td>
          </tr>

          <tr>
            <td>
              Revisie
            </td>
            <td>
              {{ sleeve.revision }}
            </td>
          </tr>

          <tr>
            <td>
              Aangemaakt
            </td>
            <td>
              {{ getHumanizedDate(sleeve.createdAt) }}
            </td>
          </tr>

          <tr>
            <td>
              Commentaar
            </td>
            <td>
              {{ sleeve.comment }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-actions>
      <v-btn
        color="primary"
        text
        @click="$emit(
          'load-events',
          {
            key: 'sleeve',
            id: sleeve._id,
            eventsBlockTitle: `Events van Sleeve ${sleeve.publicId}`,
          },
        )"
      >
        Laad events
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getHumanizedDate } from '../../helpers/humanizer';

export default {
  name: 'WolkAdministrationSleeveCard',
  props: {
    sleeve: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getHumanizedDate(millis) {
      return getHumanizedDate(millis);
    },
  },
};
</script>

<style scoped>
  .wolk-simple-table-first-column {
    width: 10em;
  }
</style>
